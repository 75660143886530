.also-by {
    margin-top: 15px;
}

.also-by section {
    margin: 20px auto 60px auto;
    padding: 30px;
    border-radius: 15px;
    overflow: hidden;
}

.alsoby-card {
    width: 100%;
    background-color: var(--body-bg-color);
    margin-bottom: 70px;
    border-radius: 15px;
    box-shadow: 0px 10px 10px 8px #888888;
}

.also-by section h2 {
    font-family: Bebas Neue, cursive;
    letter-spacing: .1rem;
}

.also-by h1 {
    margin-top: 30px;
    text-align: center;
    font-family: Hamster, cursive;
    letter-spacing: .1rem;
    font-size: 55px;
}

.also-by img {
    width: 0;
    height: 0;
}

#deweyImg {
    width: 250px;
    border-radius: 15px;
    margin-top: 30px;
}

/* if devive is bigger than */
@media only screen and (min-width: 768px) {
    .also-by img {
        float: right;

        margin-left: 15px;
        width: 200px;
        height: auto;
    }

    .alsoby-card {
        width: 80%;
    }
}

.fixing-up h6, .fixing-up p {
    margin-bottom: 0;
}

.play-info, .play-quotes {
    margin-bottom: 15px;
}

.info-button {
    color: #007bff;
    cursor: pointer;
    font-weight: 600;
}

.info-button:hover {
    color: #0256af;
    text-decoration: underline;
}

.also-by a {
    font-weight: 600;
}