.footer {
    height: 10vh;
    background-color: var(--cover-orange);
    color: white;
    position: relative;
    bottom: 0;
}

.footer ol {
    width: 100%;    
    
    margin-bottom: 0;
    
    list-style-type: none;
    text-align: center;
    font-size: small;
}

/* if device width is greater than 768 px */
@media only screen and (min-width: 768px) {
    .footer ol {
        display: flex;
        flex-flow: row wrap;
        align-items: space-between;
        justify-content: space-evenly;
        padding-top: 2vh;
        font-size: medium;
    }
}

.footer button:hover {
    background-color: white;
    color: var(--cover-orange);
}

/* remove hover fx for copyright */
#copyrightBtn:hover {
    background-color: transparent;
    color: white;
}

.footer button {
    color: white;
    background-color: transparent;
    border: thin solid white;
    border-radius: 5px;
    margin: 0;
}