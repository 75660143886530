.about {
    padding: 0 60px;
}

.about h1 {
    margin: 30px 0;
    text-align: center;
    font-family: Hamster, cursive;
    letter-spacing: .1rem;
    font-size: 55px;
}

.about img {
    float: left;
    margin: 0 15px 15px 0;

    width: 250px;
    height: auto;

    border-radius: 5px;

    transform: scaleX(-1); /* FOR PLACEHOLDER ONLY */
}