@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: Hamster;
    src: url(/static/media/Hamster.9186a5ab.otf) format('opentype');
}

:root {
    /* --cover-orange: rgb(233,92,30); */
    --cover-orange: #ff6b2d;
    --cover-black: #231f20;
    --cover-gray: #414042;
    --cover-blue: #026e95;
    --cover-teal: #018b98;
    --cover-tan: rgb(238,217,172);

    --body-bg-color: #fff7f2;

    font-family: 'Bebas Neue', cursive;
    font-family: 'Open Sans', sans-serif;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    background-color: #fff7f2 !important;
    background-color: var(--body-bg-color) !important;
}

* {
    font-family: Open Sans, sans-serif;
}

#bootstrap-overrides .btn {
    box-shadow: none;
    outline: none;
    font-family: Open Sans, sans-serif;
    font-weight: 600;
}

#bootstrap-overrides .btn-info {
    background-color: #ff6b2d;
    background-color: var(--cover-orange);
    border-color: #ff6b2d;
    border-color: var(--cover-orange);
}

#bootstrap-overrides .btn-success {
    background-color: #026e95;
    background-color: var(--cover-blue);
    border-color: #026e95;
    border-color: var(--cover-blue);
}
.header {
    height: 100vh;
    width: 100%;
    background-color: var(--cover-tan);
    color: black;
}

.header img {
    width: 0;
    height: 0;
}

.header .titleCard {
    background-color: var(--cover-orange);
    text-align: center;
    padding: 30px 15px 30px 15px;
}

.header .btn-group-vertical {
    align-items: center;
    width: 100%;
}

/* if device width is greater than 1024 px */
@media only screen and (min-width: 1024px) {
    .header img {
        width: auto;
        height: 400px;
        transform: rotateY(-180deg);
        position: absolute;
        bottom: 10px;
        right: 225px;
    }
    
    .header .titleCard {
        padding: 30px 0 20px 120px;
        border: none;
        
        margin: 0;
        width: 100%;
        height: auto;
        text-align: left;
    }
    
    .header .btn-group-vertical {
        align-items: flex-start;
        margin-top: 50px;
        padding-left: 120px;
    }
}

/* if device width is greater than 1200 and height is greater than 890 */
@media only screen and (min-width: 1200px) and (min-height: 740px) {    
    .header .titleCard {
        height: 100%;
        padding-top: 100px;
        padding-left: 220px;
    }

    .header .header-text {
        height: 40%;
    }

    .header .title {
        font-size: 60px;
    }

    .header .subtitle {
        font-size: 35px;
    }

    .header .btn-group-vertical {
        margin-left: 100px;
    }

    .header Button {
        height: 70px;
        width: auto;
        font-size: xx-large;
    }
}

.header Button {
    margin: 10px auto 10px auto;
}

.header-text {
    text-align: left;
}

.header-text .title {
    font-family: Bebas Neue, cursive !important;    
    letter-spacing: .2rem;
}

.header-text .subtitle {
    font-family: Open Sans, sans-serif;
    font-style: italic;
}

.header-text a {
    color: inherit;
}
.bitsyStories {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 70px;
    min-height: 100vh;
}

.product-card {
    margin-bottom: 100px;
    padding: 40px;
}

/* 
    --desktop: 1200px;
    --laptop: 1024px;
    --tablet: 768px;
    --phone: 480px;
*/

/* if devive is bigger than */
@media only screen and (min-width: 768px) {
    .product-card {
        width: 80%;
        background-color: var(--body-bg-color);
        margin-bottom: 70px;
        border-radius: 15px;
        box-shadow: 0px 10px 10px 8px #888888;
    }
}

.bitsyStories h2 {
    padding-top: 30px;
    margin: 0;
    text-align: center;
}

/* ==================== title animation ==================== */

#titleCycle {
    width: 100%;
    overflow: hidden; /* hide the text before it is typed */
    white-space: nowrap; /* prevents the hidden text from wrapping */

    /* the animation time has to match the interval time in BitsyStories.jsx */
    -webkit-animation: typing 8s linear, blinking-cursor .8s linear;
            animation: typing 8s linear, blinking-cursor .8s linear;
    -webkit-animation-iteration-count:infinite;
            animation-iteration-count:infinite;

    font-style: italic;

    border-right: .15em solid black; /* cursor */
}

@-webkit-keyframes blinking-cursor {
  from, to { border-color: transparent }
  50% { border-color: black }
}

@keyframes blinking-cursor {
  from, to { border-color: transparent }
  50% { border-color: black }
}

@-webkit-keyframes typing {
  0% {width: 0%; padding-right: 0}
  25% {width: 100%; padding-right: 5px} /* padding puts some space between the cursor and the text */
  50% {width: 100%; padding-right: 5px} /* pause once text is typed */
  75% {width: 0%; padding-right: 0}
  100% {width: 0%; padding-right: 0} /* pause after text is 'deleted' */
}

@keyframes typing {
  0% {width: 0%; padding-right: 0}
  25% {width: 100%; padding-right: 5px} /* padding puts some space between the cursor and the text */
  50% {width: 100%; padding-right: 5px} /* pause once text is typed */
  75% {width: 0%; padding-right: 0}
  100% {width: 0%; padding-right: 0} /* pause after text is 'deleted' */
}

/* ==================== info styles ==================== */

.book-info {
    padding-top: 50px;
}

.book-info h4 {
    text-align: center;
}

.book-info ol {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 15px;
}

.book-info Button {
    margin-right: 10px;
}

/* ==================== card styles ==================== */

/* 
    --desktop: 1200px;
    --laptop: 1024px;
    --tablet: 768px;
    --phone: 480px;
*/

/* between desktop and laptop */
@media only screen and (max-width: 1200px) and (min-width: 1024px) {
    .card {
        width: 500px;
        height: 700px;
    }
}

.bitsyStories .card-wrapper {
    perspective: 800px;
    margin: 50px 0 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bitsyStories .card-wrapper Button {
    margin-top: 10px;
}

.bitsyStories .card {
    position: relative;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 15px;

    width: 300px;
    height: 450px;

    /* flip transition */ /* Safari and Chrome */
    transition: transform 1.5s;
    transform-style: preserve-3d;
}

.bitsyStories .card-front {
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 15px;
}

.bitsyStories img {
    border-radius: inherit;
}

.bitsyStories .card-back {
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 15px;
    
    /* positioning back face */ /* IE 9*/ /* Safari */
    transform: rotateY(-180deg);
}

/* ==================== unused hover effect, saved for reference ==================== */

/* .bitsyStories .hcard:hover {
    -ms-transform: rotateY(-180deg); 
    -webkit-transform: rotateY(-180deg); 
    transform: rotateY(-180deg);
} 
*/
.about {
    padding: 0 60px;
}

.about h1 {
    margin: 30px 0;
    text-align: center;
    font-family: Hamster, cursive;
    letter-spacing: .1rem;
    font-size: 55px;
}

.about img {
    float: left;
    margin: 0 15px 15px 0;

    width: 250px;
    height: auto;

    border-radius: 5px;

    transform: scaleX(-1); /* FOR PLACEHOLDER ONLY */
}
.also-by {
    margin-top: 15px;
}

.also-by section {
    margin: 20px auto 60px auto;
    padding: 30px;
    border-radius: 15px;
    overflow: hidden;
}

.alsoby-card {
    width: 100%;
    background-color: var(--body-bg-color);
    margin-bottom: 70px;
    border-radius: 15px;
    box-shadow: 0px 10px 10px 8px #888888;
}

.also-by section h2 {
    font-family: Bebas Neue, cursive;
    letter-spacing: .1rem;
}

.also-by h1 {
    margin-top: 30px;
    text-align: center;
    font-family: Hamster, cursive;
    letter-spacing: .1rem;
    font-size: 55px;
}

.also-by img {
    width: 0;
    height: 0;
}

#deweyImg {
    width: 250px;
    border-radius: 15px;
    margin-top: 30px;
}

/* if devive is bigger than */
@media only screen and (min-width: 768px) {
    .also-by img {
        float: right;

        margin-left: 15px;
        width: 200px;
        height: auto;
    }

    .alsoby-card {
        width: 80%;
    }
}

.fixing-up h6, .fixing-up p {
    margin-bottom: 0;
}

.play-info, .play-quotes {
    margin-bottom: 15px;
}

.info-button {
    color: #007bff;
    cursor: pointer;
    font-weight: 600;
}

.info-button:hover {
    color: #0256af;
    text-decoration: underline;
}

.also-by a {
    font-weight: 600;
}

.footer {
    height: 10vh;
    background-color: var(--cover-orange);
    color: white;
    position: relative;
    bottom: 0;
}

.footer ol {
    width: 100%;    
    
    margin-bottom: 0;
    
    list-style-type: none;
    text-align: center;
    font-size: small;
}

/* if device width is greater than 768 px */
@media only screen and (min-width: 768px) {
    .footer ol {
        display: flex;
        flex-flow: row wrap;
        align-items: space-between;
        justify-content: space-evenly;
        padding-top: 2vh;
        font-size: medium;
    }
}

.footer button:hover {
    background-color: white;
    color: var(--cover-orange);
}

/* remove hover fx for copyright */
#copyrightBtn:hover {
    background-color: transparent;
    color: white;
}

.footer button {
    color: white;
    background-color: transparent;
    border: thin solid white;
    border-radius: 5px;
    margin: 0;
}
