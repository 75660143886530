.header {
    height: 100vh;
    width: 100%;
    background-color: var(--cover-tan);
    color: black;
}

.header img {
    width: 0;
    height: 0;
}

.header .titleCard {
    background-color: var(--cover-orange);
    text-align: center;
    padding: 30px 15px 30px 15px;
}

.header .btn-group-vertical {
    align-items: center;
    width: 100%;
}

/* if device width is greater than 1024 px */
@media only screen and (min-width: 1024px) {
    .header img {
        width: auto;
        height: 400px;
        transform: rotateY(-180deg);
        position: absolute;
        bottom: 10px;
        right: 225px;
    }
    
    .header .titleCard {
        padding: 30px 0 20px 120px;
        border: none;
        
        margin: 0;
        width: 100%;
        height: auto;
        text-align: left;
    }
    
    .header .btn-group-vertical {
        align-items: flex-start;
        margin-top: 50px;
        padding-left: 120px;
    }
}

/* if device width is greater than 1200 and height is greater than 890 */
@media only screen and (min-width: 1200px) and (min-height: 740px) {    
    .header .titleCard {
        height: 100%;
        padding-top: 100px;
        padding-left: 220px;
    }

    .header .header-text {
        height: 40%;
    }

    .header .title {
        font-size: 60px;
    }

    .header .subtitle {
        font-size: 35px;
    }

    .header .btn-group-vertical {
        margin-left: 100px;
    }

    .header Button {
        height: 70px;
        width: auto;
        font-size: xx-large;
    }
}

.header Button {
    margin: 10px auto 10px auto;
}

.header-text {
    text-align: left;
}

.header-text .title {
    font-family: Bebas Neue, cursive !important;    
    letter-spacing: .2rem;
}

.header-text .subtitle {
    font-family: Open Sans, sans-serif;
    font-style: italic;
}

.header-text a {
    color: inherit;
}