.bitsyStories {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 70px;
    min-height: 100vh;
}

.product-card {
    margin-bottom: 100px;
    padding: 40px;
}

/* 
    --desktop: 1200px;
    --laptop: 1024px;
    --tablet: 768px;
    --phone: 480px;
*/

/* if devive is bigger than */
@media only screen and (min-width: 768px) {
    .product-card {
        width: 80%;
        background-color: var(--body-bg-color);
        margin-bottom: 70px;
        border-radius: 15px;
        box-shadow: 0px 10px 10px 8px #888888;
    }
}

.bitsyStories h2 {
    padding-top: 30px;
    margin: 0;
    text-align: center;
}

/* ==================== title animation ==================== */

#titleCycle {
    width: 100%;
    overflow: hidden; /* hide the text before it is typed */
    white-space: nowrap; /* prevents the hidden text from wrapping */

    /* the animation time has to match the interval time in BitsyStories.jsx */
    animation: typing 8s linear, blinking-cursor .8s linear;
    animation-iteration-count:infinite;

    font-style: italic;

    border-right: .15em solid black; /* cursor */
}

@keyframes blinking-cursor {
  from, to { border-color: transparent }
  50% { border-color: black }
}

@keyframes typing {
  0% {width: 0%; padding-right: 0}
  25% {width: 100%; padding-right: 5px} /* padding puts some space between the cursor and the text */
  50% {width: 100%; padding-right: 5px} /* pause once text is typed */
  75% {width: 0%; padding-right: 0}
  100% {width: 0%; padding-right: 0} /* pause after text is 'deleted' */
}

/* ==================== info styles ==================== */

.book-info {
    padding-top: 50px;
}

.book-info h4 {
    text-align: center;
}

.book-info ol {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 15px;
}

.book-info Button {
    margin-right: 10px;
}

/* ==================== card styles ==================== */

/* 
    --desktop: 1200px;
    --laptop: 1024px;
    --tablet: 768px;
    --phone: 480px;
*/

/* between desktop and laptop */
@media only screen and (max-width: 1200px) and (min-width: 1024px) {
    .card {
        width: 500px;
        height: 700px;
    }
}

.bitsyStories .card-wrapper {
    perspective: 800px;
    margin: 50px 0 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bitsyStories .card-wrapper Button {
    margin-top: 10px;
}

.bitsyStories .card {
    position: relative;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 15px;

    width: 300px;
    height: 450px;

    /* flip transition */
    -webkit-transition: transform 1.5s; /* Safari and Chrome */
    transition: transform 1.5s;
    transform-style: preserve-3d;
}

.bitsyStories .card-front {
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    border-radius: 15px;
}

.bitsyStories img {
    border-radius: inherit;
}

.bitsyStories .card-back {
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    border-radius: 15px;
    
    /* positioning back face */
    -ms-transform: rotateY(-180deg); /* IE 9*/
    -webkit-transform: rotateY(-180deg); /* Safari */
    transform: rotateY(-180deg);
}

/* ==================== unused hover effect, saved for reference ==================== */

/* .bitsyStories .hcard:hover {
    -ms-transform: rotateY(-180deg); 
    -webkit-transform: rotateY(-180deg); 
    transform: rotateY(-180deg);
} 
*/